.RadioBox {
    text-align: left;
}

.RadioBox .MuiFormControl-root {
    text-align: left;
}

.RadioBox .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
}

.RadioBox legend.MuiFormLabel-root {
    font-size: 1.6em;
    color: var(--secColor);
}

/*checkoutRadio*/

.cardInnrRadio {
    position: relative;
    border: 1px solid var(--primColor);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    /* padding-right: 65px; */
    align-items: center;
    justify-content: flex-start;
    background: #ffeded;
    max-width: 250px;
    width: 100%;
}

.cardInnrRadio .MuiTypography-root.MuiFormControlLabel-label {
    width: 100%;
}

.cardInnrRadio .MuiFormControlLabel-root {
    margin: 0;
    width: 100%;
}

.cardInnrRadio .MuiButtonBase-root.MuiRadio-root {
    position: absolute;
    top: 0px;
    right: 0;
}

.filterWrp {
    width: 280px;
    height: 1189px;
    overflow-y: auto;
    display: none;
}
.filterWrp::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.filterWrp::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
.filterWrp::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
.filterWrp::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.SearchCnt {
    background-color: white;
    overflow-x: hidden;
    /* padding-right: 60px; */
    /* padding-left: 60px; */
}
.searchTopCnt {
    padding-top: 15px;
    padding-right: 60px;
    border-bottom: 1px solid #eeeeee;
    height: 75px;
}

.searchTopCnt .topcntSearchWrp {
    align-items: center;
}
.title {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    /* width: 340px; */
    border: 1px solid #eeeeee;
    padding: 15px;
    padding-left: 40px;
    text-align: left;
    border-radius: 6px 6px 0px 0px;
}
.searchPdtCnt {
    /* width: calc(100% - 280px); */
    width: 100%;
    border: 1px solid #eeeeee;
    margin-left: 0;
    border-radius: 6px 6px 0px 0px;
}
.resultPrePage p {
    margin: 0px;
    padding-right: 10px;
    padding-left: 25px;
}
.searchPdtCnt .MuiButtonBase-root.MuiButton-root {
    min-width: unset;
}

.searchPdtCnt .srchVwDtlsCntnr {
    min-height: 60vh;
}

.result {
    margin: 0px;
    padding-left: 20px;
    font-size: 15px;
    font-style: italic;
    font-family: var(--fontFamily1);
    color: #737373;
}
.searchResult {
    padding: 30px 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 30px;
}
.searchList {
    padding: 30px 25px 15px 25px;
}
.SearchCnt .primButton .MuiButton-label {
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
}
.SearchCnt .MuiFormControl-root {
    min-width: unset;
}
.active {
    color: var(--primColor);
}

.topcntSearchWrp .MuiButton-root:hover {
    background: #ffc9c9;
}
.filterMb {
    display: none;
}

/* responsive css */

@media (max-width: 1024px) {
    .filter .MuiTypography-root {
        padding-left: 5px;
    }
    .filterMb button {
        color: #fff;
        padding: 10px;
        width: 100%;
    }
    .filterMb button span {
        padding-right: 6px;
    }
    .filterMb {
        display: block;
        width: 120px;
        background: var(--primColor);
        margin: 15px 45px;
    }
    .SearchCnt {
        padding-right: initial;
    }
    .searchPdtCnt {
        width: 100%;
        margin-left: 40px;
        margin-right: 40px;
    }
    .filterWrp {
        display: none;
    }
}

@media (max-width: 991px) {
    .resultPrePage p {
        padding-right: 5px;
        padding-left: 0;
    }
    .pageNo .MuiPagination-ul {
        justify-content: center;
    }
    .searchTopCnt {
        padding-right: 0;
    }
    .resultPrePage .MuiFormControl-root {
        min-width: auto;
    }
}

@media (max-width: 768px) {
    .resultPrePage .makeStyles-formControl-24 {
        min-width: auto;
    }
    .result {
        padding-left: 0;
    }
    .searchTopCnt {
        padding-top: 0;
        padding-right: 0;
    }
}

@media (max-width: 650px) {
    .searchPdtCnt .topcntSearchWrp .MuiButtonBase-root.MuiButton-root {
        display: none;
    }
    .searchResult {
        padding: 30px 10px;
        grid-gap: 10px;
    }
}

@media (max-width: 600px) {
    .searchPdtCnt {
        margin-left: 0px;
        margin-right: 0px;
    }
    .filterMb {
        margin: 15px 20px;
    }
}

@media (max-width: 592px) and (min-width: 561px) {
    .gridViewCnt {
        max-width: 220px;
    }
    .searchResult {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}

@media (max-width: 560px) {
    /* .filterMb{
        margin: 15px 20px;
    } */
}

@media (max-width: 560px) {
    .resultPrePage .MuiFormControl-root {
        min-width: auto;
    }
    .resultPrePage p {
        padding-right: initial;
        padding-left: initial;
        text-align: left;
        white-space: nowrap;
    }
    .searchResult {
        padding: 20px 20px;
        grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
        grid-gap: 10px;
    }
    .searchTopCnt {
        flex-direction: column;
    }
    .result {
        display: block;
        width: 100%;
        background: #00338026;
        padding: 3px;
    }
}
@media (max-width: 465px) {
    .gridViewCnt {
        margin: auto;
    }
}

@media (max-width: 425px) {
    .gridViewCnt {
        margin-bottom: 10px;
    }
}
@media (max-width: 375px) {
    .searchResult {
        padding: 20px 10px;
    }
    .gridViewCnt {
        max-width: 100%;
    }
    .resultPrePage .MuiFormControl-root {
        margin: 8px auto !important;
    }
    .resultPrePage .MuiFormControl-root .MuiSelect-select.MuiSelect-select,
    .resultPrePage p {
        font-size: 13px;
    }
}
@media (max-width: 320px) {
    .searchTopCnt {
        height: auto;
    }
    .resultPrePage {
        padding: 5px 0;
    }
    .resultPrePage p {
        font-size: 13px;
    }
    .makeStyles-formControl-24 {
        margin: auto;
    }
}

.Dashboard .Favourite-Header {
    width: 200px;
    margin: 0 0 25px auto;
    padding-right: 25px;
}
.Dashboard .Favflex {
    padding: 0px 8px;
}

.Dashboard .Favourites-cards {
    display: flex;
    flex-wrap: wrap;
}

.Dashboard .fav-pagination .MuiPagination-root .MuiPagination-ul {
    width: max-content;
    margin: 20px auto;
}

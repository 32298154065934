.checkoutContainer {
    padding: 25px 60px;
    background-color: white;
}
.checkoutPdt {
    width: 100%;
}
.checkoutContainer .checkoutTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    text-align: left;
}
.checkoutContainer .paymentTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}

.checkoutPdt .cardHolder {
    margin-bottom: 20px;
}

.Login {
    display: flex;
    justify-content: center;
    text-align: center;
}

.custom-container {
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.loginField .logo {
    margin-bottom: 40px;
}

.loginField .logo img {
    width: 100%;
    max-width: 350px;
}

.loginField {
    padding-top: 60px;
    background-color: white;
}

.Login .loginText {
    color: #002c6e;
    margin-bottom: 20px;
}

.Login .loginText h1 {
    font-size: 26px;
    font-weight: 600;
}

.Login .welcomeText {
    color: var(--primColor);
    margin: 30px 0 30px 0;
}

.Login .welcomeText h1 {
    font-size: 24px;
    font-weight: 600;
}

.Login .endInput {
    display: flex;
    margin-bottom: 40px;
    margin-top: 0;
}

.loginButton .MuiButton-label {
    text-transform: none;
}

.Login .bottomText a:link {
    border-bottom: 1px solid #002c6e;
    text-decoration: none;
    font-weight: 600;
}

.Login .bottomText a {
    color: #021431;
}

.Login .loginInput .customInput:nth-of-type(1) {
    margin-bottom: 30px;
}

.loginField .loginInput .MuiInputBase-input {
    padding-bottom: 15px;
}

.loginField .loginButton .primButton .MuiButtonBase-root {
    width: 191px;
}

.Login .bottomText {
    margin-top: 30px;
}

.coinImg {
    width: 50%;
}

.coinImg img {
    height: 100vh;
    width: auto;
}

.Login .forgotPassword {
    margin: 10px 0 0 auto;
    color: #999999;
}

.Login .forgotPassword a:link {
    text-decoration: none;
}
.Login .forgotPassword a {
    color: #999999;
}

.Login .endInput .MuiFormGroup-root .MuiButtonBase-root {
    padding-left: 0;
    margin-left: 8px;
    color: #999999;
}

.Login .LoginformikError {
    color: red;
    font-size: 14px;
}

.invalidInp-1 {
    font-size: 14px;
    color: red;
    width: 100%;
    text-align: left;
}

.invalidInp-2 {
    font-size: 14px;
    color: red;
    margin: 30px 0 0 0;
    width: 100%;
    text-align: left;
}

.Login .loginField {
    width: 50% !important;
}

/* responsive */

@media (max-width: 630px) {
    .Login {
        flex-wrap: wrap;
    }

    .Login .loginField {
        width: 100% !important;
    }

    .loginField.custom-container {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
        padding-top: 100px !important;
    }
    .loginField .logo {
        width: 230px;
        height: 65px;
        z-index: 9999;
        display: block;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 120px;
        left: 0;
        right: 0;
        background: white;
        border-radius: 10px;
        padding: 10px;
    }

    .loginField .logo img {
        width: 100%;
    }
}
@media (max-width: 580px) {
    .loginField.custom-container {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}
@media (max-width: 475px) {
    .Login .endInput .MuiFormGroup-root .MuiButtonBase-root {
        padding-right: 3px;
        font-size: 14px;
    }
    .Login .forgotPassword {
        font-size: 14px;
    }
}

.productCntWrp {
    background-color: white;
    padding-top: 30px;
}
.productCntWrp .pdtContent {
    padding-left: 20px;
}
.productCntWrp .productDescp {
    padding: 55px 60px;
}
.productCntWrp .productDescp img {
    width: 420px;
    height: 280px;
    border-radius: 5px;
    object-fit: contain;
    position: sticky;
    background-color: #dcdcdc59;
    top: 25px;
}
.productCntWrp .productTitle {
    font-size: 24px;
    font-family: var(--fontFamily1);
    font-weight: 800;
    color: var(--secColor);
    text-align: left;
    margin: 0px;
    padding-bottom: 5px;
}
.productCntWrp .productCat {
    font-size: 14px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: #c5c5c5;
    text-align: left;
    margin: 0px;
    /* padding-bottom: 25px; */
}
.dateTimeWrp {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
}
.productCntWrp .dateTitle {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    margin: 0px;
    padding-bottom: 5px;
}
.productCntWrp .dateTime {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    margin: 0px;
    /* padding-left: 20px; */
}
.enddateTime {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    margin: 0px;
    /* padding-left: 29px; */
}
.descrTitle {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    text-align: left;
    margin: 0px;
    padding-bottom: 5px;
}
.description {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    color: #707070;
    max-width: 605px;
    width: 100%;
    text-align: left;
    margin: 0px;
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}
.itemTitle {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    margin: 0px;
}
.itemsWrp {
    text-align: left;
}
.items {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    /* padding-left: 30px; */
    margin: 0px;
}
.productCntWrp .Days p {
    font-size: 15px;
    font-family: var(--fontFamily1);
    font-weight: 700;
}
.productCntWrp .Hrs p {
    font-size: 15px;
    font-family: var(--fontFamily1);
    font-weight: 700;
}
.productCntWrp .Mins p {
    font-size: 15px;
    font-family: var(--fontFamily1);
    font-weight: 700;
}
.productCntWrp .Sec p {
    font-size: 15px;
    font-family: var(--fontFamily1);
    font-weight: 700;
}
.productCntWrp .timerCnt h4 {
    padding-bottom: 5px;
}
.productCntWrp .dot {
    font-size: 20px;
    font-family: var(--fontFamily1);
}
.tableHeader {
    background-color: var(--secColor);
}
.tableHeader th {
    color: white;
    vertical-align: middle;
}
.placeBid {
    /* width: 302px; */
    flex-wrap: wrap;
    max-width: 381px;
}
.placeBid .MuiButton-label {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    text-transform: none;
}
.placeBid .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.placeBid .primButton {
    width: 100%;
    margin-top: 10px;
}

.placeBid .customInput {
    width: 100%;
}

.placeBid .MuiButtonBase-root.MuiButton-root {
    width: 100%;
}
.placeBid .makeStyles-root-7.primButton {
    padding-left: 15px;
}
.lotTime .timerCnt {
    flex-direction: column;
    /* align-items: center; */
}
.productCntWrp .lotTime .timerCnt {
    padding: 0px;
}
.productCntWrp .lotTime .closed {
    padding-bottom: 20px;
}
.lotImg img {
    width: 167px;
    object-fit: contain;
}
.lotTime .dot {
    display: none;
}
.lotTime .Days {
    display: flex;
    padding-bottom: 10px;
    align-items: baseline;
}
.lotTime .Hrs {
    display: flex;
    padding-bottom: 10px;
    align-items: baseline;
}
.lotTime .Mins {
    display: flex;
    padding-bottom: 10px;
    align-items: baseline;
}
.lotTime .Sec {
    display: flex;
    align-items: baseline;
}
.lotTime .Days p {
    padding-left: 5px;
}
.lotTime .Hrs p {
    padding-left: 5px;
}
.lotTime .Mins p {
    padding-left: 5px;
}
.lotTime .Sec p {
    padding-left: 5px;
}
.tableCnt td,
.tableCnt th {
    text-align: left;
}
.tableCnt th:nth-last-child(1) {
    text-align: center;
    width: 302px;
}
.tableCnt th:nth-of-type(2) {
    width: 167px;
}
/* .tableCnt th:nth-of-type(4) {
    width: 186px;
} */
.tableCnt th:nth-of-type(5) {
    width: 67px;
}
.tableCnt th:nth-of-type(6) {
    width: 100px;
}
.tableCnt th:nth-of-type(7) {
    width: 75px;
}
.tableCnt th:nth-of-type(8) {
    width: 130px;
}
.slNo {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}
.cat a {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
}
.decs {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 135px;
}
.CurrentPrice {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}
.bids a {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    cursor: pointer;
}
.bids a:hover {
    text-decoration: underline !important;
}
.productCntWrp .image-gallery {
    padding: 60px 70px 10px 60px;
}
.productCntWrp .imgBtn {
    cursor: pointer;
}
.titleFav {
    padding-top: 25px;
}
.cursor {
    cursor: pointer;
}
.Favicon {
    font-size: 30px;
    color: var(--primColor);
    cursor: pointer;
}
.closeBtn {
    font-size: 30px;
    cursor: pointer;
    color: var(--primColor);
}
.lotViewTitle {
    font-size: 24px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    margin: 0px;
    margin-bottom: 15px;
}
.lotDecs {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    margin: 0px;
    /* padding-bottom: 10px;
    padding-top: 25px; */
}
.lotDecsCnt {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    padding-bottom: 25px;
    margin: 0px;
}
.bidWrp {
    padding-right: 165px;
}
.totlaBidsTitle {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    margin: 0px;
    padding-right: 25px;
}
.bidNo {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    margin: 0px;
}
.DateTime {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    margin: 0px;
    padding-right: 25px;
}
.timerBid {
    padding-top: 35px;
}
.timerBid .timerCnt {
    padding-right: 105px;
}

.timerBid.pdtVwSlider .timeTitle {
    padding-right: 105px;
    text-align: center;
    padding-bottom: 5px;
}

.lotTime .Sec {
    display: flex;
    align-items: baseline;
}
.timerBid .Days p {
    font-size: 17px;
    font-weight: 700;
    padding-top: 10px;
}
.timerBid .Hrs p {
    font-size: 17px;
    font-weight: 700;
    padding-top: 10px;
}
.timerBid .Mins p {
    font-size: 17px;
    font-weight: 700;
    padding-top: 10px;
}
.timerBid .Sec p {
    font-size: 17px;
    font-weight: 700;
    padding-top: 10px;
}
.timerBid .Days h4 {
    font-size: 18px;
    font-weight: 600;
}
.timerBid .Hrs h4 {
    font-size: 18px;
    font-weight: 600;
}
.timerBid .Mins h4 {
    font-size: 18px;
    font-weight: 600;
}
.timerBid .Sec h4 {
    font-size: 18px;
    font-weight: 600;
}
.lotPdtWrp .table thead th {
    border: none;
}
.lotPdtWrp .timeTitle {
    padding-bottom: 5px;
}
.productCntWrp .image-gallery-thumbnails {
    text-align: left !important;
}
.pageNo .MuiPagination-ul {
    justify-content: flex-end;
    padding: 15px 60px;
}
.pageNo .MuiPaginationItem-page.Mui-selected {
    background-color: #f9b1b1;
}
.pageNo .MuiPaginationItem-page:hover {
    background-color: #f9b1b1;
}
.lotPdtWrp .table td .onclisk b {
    display: none;
}
.productCntWrp .Mui-disabled {
    border: none;
}

.publicMsgContainer {
    margin-bottom: 15px;
}

.lotPdtWrp table .closed {
    font-size: 16px;
    padding-top: 10px;
}

.adminMsgTimeDate {
    text-align: left;
}

.publicAccount {
    justify-content: flex-end;
}

.Notification-Panel .MuiFormControlLabel-root {
    display: inline-flex;
    align-items: center;
    margin-left: unset !important;
    margin-right: 0;
    margin-bottom: 0;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.Dashboard .Notification-Panel .table tr td:last-child .MuiFormGroup-root.MuiFormGroup-row {
    justify-content: center !important;
}

.filter .MuiTypography-root {
    white-space: pre-wrap;
    max-width: 202px;
}

.placeBid input::-webkit-input-placeholder {
    white-space: pre-line;
    position: relative;
    top: -6px;
    line-height: 15px;
    font-size: 13px;
}

.lotGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.lotGrid .pdtContent {
    padding-left: 0;
}

.lotGrid .gridViewCnt {
    max-width: initial;
    height: 100%;
}

.lotGrid .placeBid {
    width: 100%;
    padding: 0 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 15px 0;
}

.lotGrid .placeBid.gridViewbtn {
    grid-template-columns: 1fr;
}

.lotGrid .cards {
    height: 100%;
}

.lotGrid .pdtDescrp {
    display: none;
}

.lotGrid .currPrice {
    margin: 10px 0;
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

/* .lotGrid .placeBid .customInput{
    width: 50%;
} */

.lotGrid .placeBid .primButton {
    padding-top: 0;
    margin-top: 0;
}

.lotGrid .placeBid .primButton button {
    border-radius: 5px;
}

.lotGrid .placeBid .MuiInputBase-root {
    height: 50px;
}

.lotGrid .placeBid input.MuiInputBase-input.MuiOutlinedInput-input::placeholder {
    font-size: 16px;
    padding-top: 30px;
}

.gridListToggle {
    margin: 10px 0 20px;
    width: max-content;
    margin-left: auto;
    background: #f4f4f4;
}

.gridListToggle button {
    min-width: max-content;
}

.gridListToggle button.active {
    color: var(--primColor);
}

.gridListToggle button .material-icons {
    padding-right: 5px;
}

.lotGrid .cardImage,
.lotGrid .pdtName {
    cursor: pointer;
}

.pdtContent .productTimer .vwLvActn .primButton {
    width: 100%;
}

.pdtContent .productTimer .vwLvActn .primButton button {
    border-radius: 6px !important;
    width: 100%;
}

.pdtVwRp .productTimer {
    border-bottom: 1px solid #dadada;
    padding-bottom: 15px;
}

.pdtVwRp .productTimer .lotTimer {
    padding-right: 25px;
}

.pdtVwRp .productTimer .lotTimer .emptyTimerDiv {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdtVwRp .productTimer .lotTimer .emptyTimerDiv img {
    width: 25px;
    margin-right: 5px;
}

/* .pdtVwRp .productTimer .lotTimer.brdrRt {
    border-right: 1px solid rgb(206, 206, 206);
} */

.pdtVwRp .pdtNmInfo small {
    font-size: 14px;
    color: grey;
    padding: 5px 10px;
    background: whitesmoke;
    border-radius: 100px;
}

.pulse {
    animation: pulse 1s infinite ease-in-out alternate;
}

.enterLvTst {
    width: 100%;
    padding: 10px;
    background: whitesmoke;
    text-align: center;
}

.lotInfoReg {
    background: #ffe8c3;
    padding: 10px;
    color: #851d05;
    font-weight: 600;
    font-size: 17px;
}

.pdtVwRp .productTimer .pdtNmInfo {
    max-width: 60%;
    width: 100%;
}

.pdtVwCntWrp select {
    height: 36px;
    width: 80px;
    border-color: rgb(201, 201, 201);
    padding-left: 5px;
}

.shwMrBtn {
    text-transform: uppercase;
    font-size: 13px;
}

.shwMrBtn:hover {
    color: var(--secColor);
}

@keyframes pulse {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

/* responsve css */
@media (max-width: 1280px) {
    .productCntWrp .productDescp {
        padding: 55px 30px;
    }
    .productCntWrp .productDescp img {
        width: 45%;
    }
    .adminMsgCnt {
        padding: 12px 25px 12px 25px;
    }
    .productCntWrp .pdtContent {
        max-width: 55%;
    }
    .lotGrid .pdtContent {
        max-width: 100%;
    }
    .chatContainer {
        width: 600px;
    }
    .chatContainer .primButton.large button,
    .chatContainer .MuiOutlinedInput-root,
    .adminMsgContainer {
        width: 100%;
        max-width: 100%;
    }
    .adminMsgCnt {
        width: auto;
        max-width: max-content;
    }
    .chatContainer .primButton {
        width: 25%;
    }
    .chatContainer .textArea .customInput {
        width: 75%;
    }
}
@media (max-width: 1200px) {
    .chatContainer {
        width: 100%;
    }
    .lotImg img {
        width: 100px;
    }
    /* .productCntWrp.customContainer {
        padding-left: 25px !important;
        padding-right: 25px !important;
    } */
    .tableCnt td .closed {
        font-size: 14px;
    }
    .tableCnt td .MuiTypography-root {
        font-size: 14px;
    }
    .tableCnt td,
    .tableCnt th {
        font-size: 14px;
    }
}
@media (max-width: 1060px) {
    .productCntWrp .productTitle,
    .closed {
        font-size: 18px;
    }
    .itemTitle,
    .productCntWrp .dateTitle {
        font-size: 16px;
    }
    .items,
    .productCntWrp .dateTime {
        font-size: 15px;
    }
    .placeBid .MuiButtonBase-root.MuiButton-root,
    .tableCnt th:nth-last-child(1) {
        width: auto;
    }
    .placeBid .MuiFormControl-root.MuiTextField-root {
        width: 120px;
    }
    .placeBid {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .dateTimeWrp {
        flex-wrap: wrap;
    }
}
@media (max-width: 860px) {
    table.table.tableCnt {
        white-space: nowrap;
        max-width: 100%;
        overflow: auto;
        display: block;
    }
    .tableCnt td.decs p {
        word-break: break-word;
        white-space: pre-wrap;
        width: 170px;
    }
}
@media (max-width: 750px) {
    .timerBid .timerCnt {
        white-space: nowrap;
        padding-right: 5px;
    }
    .placeBid .MuiFormControl-root.MuiTextField-root {
        width: auto;
    }
    .imgDrawer .MuiPaper-root [role='presentation'] {
        width: 100vw;
    }
    .bidWrp {
        padding-right: 10px;
    }
}
@media (max-width: 700px) {
    .timeProduct.d-flex.justify-content-between.align-items-center {
        flex-direction: column;
    }
    .lotTime .timerCnt {
        flex-direction: row;
    }

    .lotTime .dot {
        display: block;
        padding: 2px;
        margin-right: 0 !important;
    }

    .productCntWrp .productDescp {
        padding: 20px 10px;
    }
    .lotTime .Days,
    .lotTime .Hrs,
    .lotTime .Mins,
    .lotTime .Sec,
    .productCntWrp .timerCnt h4 {
        padding-bottom: 0;
    }
    .lotPdtWrp .table td .onclisk b {
        color: var(--primColor);
        text-align: left;
        display: block;
        text-transform: lowercase;
    }
    .lotPdtWrp .table td {
        min-width: 100%;
    }
    .lotPdtWrp .table thead th br {
        display: none;
    }
    .tableCnt td.decs p {
        width: 200px;
        text-align: left;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .placeBid .MuiButtonBase-root.MuiButton-root {
        height: 35px;
    }

    .lotGrid .placeBid .MuiButtonBase-root.MuiButton-root {
        height: 50px;
    }
}
@media (max-width: 650px) {
    .productCntWrp .productDescp {
        flex-direction: column;
    }
    .productCntWrp .productDescp img {
        width: 100%;
        height: 150px;
        top: 84px;
        background: #f5f5f5;
        z-index: 1;
    }

    .productCntWrp .pdtContent {
        max-width: 100%;
        padding-left: 0;
        padding-top: 25px;
    }
    .timerBid {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .placeBid {
        width: 100%;
        margin-right: auto;
        justify-content: flex-start !important;
        flex-wrap: nowrap;
    }
    .dateWrp.d-flex,
    .bidDate.d-flex {
        flex-direction: column;
    }
    .imgDrawer .MuiPaper-root [role='presentation'] {
        padding: 25px;
    }
}

@media (max-width: 600px) {
    .pdtVwRp.px-5 {
        padding: 0 20px !important;
    }
    .lotInfoReg {
        font-size: 13px;
    }
    .productCntWrp .productTitle {
        /* text-align: center; */
    }
    .pdtVwRp .productTimer {
        padding-top: 25px;
        flex-direction: column;
    }
    .pdtVwRp .productTimer .pdtNmInfo {
        /* text-align: center!important; */
        max-width: 100%;
    }
    .pdtVwRp .productTimer .lotTimer.brdrRt {
        border-right: 1px solid transparent;
        padding-right: 0;
        margin-right: 0 !important;
        padding: 15px;
        display: none;
    }

    .pdtVwRp .productTimer .vwLvActn {
        margin-top: 15px;
    }
    .pdtVwRp .productTimer .timerdiV {
        flex-direction: column;
        width: 100%;
    }

    .pdtVwCntWrp {
        flex-wrap: wrap-reverse;
    }

    .pdtVwCntWrp .gridListToggle.mb-0 {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 475px) {
    .adminMsgCnt {
        padding: 6px 12px;
    }
}
@media (max-width: 425px) {
    .lotViewTitle {
        font-size: 16px;
    }
}

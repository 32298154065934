:root {
    --fontFamily1: 'Montserrat', sans-serif;
    --fontFamily2: 'Montserrat', sans-serif;
    --fontComfortaa: 'Montserrat', sans-serif;
    --primColor: #fec52e;
    --secColor: #080808;
    --accentColor: black;
    --backgroundColor: #f5f5f5;
}

.primButton button,
.secButton button {
    height: 50px;
}

.primButton.small button,
.secButton.small button {
    height: 50px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #4e4e4e;
    background: #ffddd6;
}

::selection {
    color: #4e4e4e;
    background: #ffddd6;
}

@media (max-width: 500px) {
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        height: 45px;
        font-size: 13px;
    }
}

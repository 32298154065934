* {
    box-sizing: border-box;
}

.Banner {
    display: flex;
    justify-content: center;
    text-align: center;
}

.custom-container-resetpass {
    padding-top: 140px;
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.custom-container-resetpass .coinLogo-resetpassword {
    margin-bottom: 60px;
}

.custom-container-resetpass .resetPassword-text {
    color: #002c6e;
    margin-bottom: 30px;
}

.custom-container-resetpass .resetPassword-text h3 {
    font-weight: 600;
}

.custom-container-resetpass .customInput .MuiFormControl-root {
    margin-bottom: 30px;
}

.custom-container-resetpass .MuiButtonBase-root {
    margin-bottom: 30px;
}

.custom-container-resetpass .MuiButtonBase-root {
    width: 250px;
    text-transform: none;
}

.custom-container-resetpass a {
    text-decoration: none;
    color: #002c6e;
    border-bottom: 1px solid #002c6e;
}

.ResetpassError {
    color: red;
    text-align: left;
}
.ResetpassError1 {
    color: red;
    text-align: left;
    margin-bottom: 10px;
}

.coinLogo-resetpassword img {
    width: 300px;
}

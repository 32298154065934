.Addcard-Payment {
    border: 2px solid rgb(199, 199, 199);
    border-style: dashed;
    padding: 30px;
    border-radius: 16px;
}
.AddCard-Popup-Banner {
    padding-left: 50px !important;
    padding-right: 50px !important;
    width: 100% !important;
}

.addNewcard-parent .makeStyles-paper-21 .custom-modal .modal-dialog.modal-lg {
    width: 500px;
}

/* .Addcard-Popup-Input {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 33.333333%;
    justify-content: space-between;
    margin: 40px 0 50px 0;
} */

/* .Addcard-Popup-Input .customInput:nth-of-type(1) {
    width: 300px;
    margin-bottom: 20px;
}

.Addcard-Popup-Input .customInput:nth-of-type(2) {
    width: 110px;
    margin-bottom: 20px;
}

.Addcard-Popup-Input .customInput:nth-of-type(3) {
    width: 110px;
    margin-bottom: 20px;
}

.Addcard-Popup-Input .customInput:nth-of-type(4) {
    width: 300px;
}

.Addcard-Popup-Input .customInput:nth-of-type(5) {
    width: 250px;
} */

.AddCard-Popup-Banner .MuiButtonBase-root {
    width: 150px;
    margin-bottom: 15px;
}

/* responsive css */

@media (max-width: 425px) {
    .AddCard-Popup-Banner {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

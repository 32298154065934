.Dashboard .Full-Container h4 {
    margin: 10px 0 24px 0;
}

.Dashboard .Full-Container {
    padding-left: 120px !important;
    padding-right: 120px !important;
    width: 100% !important;
    padding-top: 10px !important;
}

.Full-Container .Profilepic-Profile {
    margin-bottom: 20px;
    position: relative;
}

.Dashboard .Full-Container .MuiFormHelperText-root {
    color: red;
}

.Dashboard .Full-Container .Profile-Container-personalDetails {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.profileButtons .MuiButton-label {
    font-family: var(--fontFamily1);
}
.Dashboard .Full-Container .Profile-Container-personalDetails > div {
    flex: 0 50%;
}

.Dashboard .Full-Container .Profile-Container-LocationDetails {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Dashboard .Full-Container .Profile-Container-LocationDetails > div {
    flex: 0 50%;
}

.Dashboard .Full-Container .profileButtons {
    display: flex;
    justify-content: center;
}

.Dashboard .Full-Container .changePassword-Header {
    display: flex;
}

.Dashboard .Full-Container .ProfilePasswordInput {
    display: flex;
    flex-wrap: wrap;
}

.Dashboard .Full-Container .ProfilePasswordInput > div {
    flex: 0 50%;
}

.Dashboard h4 {
    font-size: 20px;
    font-weight: 700;
}

.Dashboard .Full-Container .Profile-Container-personalDetails .MuiFormControl-root {
    width: 300px;
    margin-bottom: 30px;
}

.Dashboard .Full-Container .Profile-Container-personalDetails {
    margin-bottom: 30px;
}

.Dashboard .Full-Container h5 {
    text-align: left;
    padding-left: 0px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 45px;
}

.Dashboard .Full-Container .Profile-Container-LocationDetails {
    margin-bottom: 15px;
}

.Dashboard .Full-Container .Profile-Container-LocationDetails .MuiFormControl-root {
    width: 300px;
    margin-bottom: 30px;
}

.Dashboard .Full-Container .profileButtons .MuiButtonBase-root {
    width: 150px;
    margin-right: 30px;
    text-transform: none;
}

.Dashboard .Full-Container .profileButtons {
    margin-bottom: 40px;
}

.Dashboard .Full-Container .changePassword-Header .MuiSwitch-root {
    margin-left: auto;
}

.Dashboard .Full-Container .ProfilePasswordInput .MuiFormControl-root {
    width: 300px;
    margin-bottom: 30px;
}

.Dashboard .Full-Container .pp-edit {
    border-radius: 50%;
    width: 32px;
    /* height: 32px; */
    background: white;
    color: var(--primColor);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: block;
    position: absolute;
    text-align: center;
    padding: 3px;
    right: 19px;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    left: 100px;
}

.Dashboard .Profilepic-Profile img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
}

.Dashboard
    .Full-Container
    .Profile-Container-personalDetails
    > div.customInput:nth-of-type(3)
    .MuiInputBase-input {
    color: black;
    background-color: rgb(213, 236, 186);
}

.Profile-Container-personalDetails .intlIptWrpr {
    text-align: unset;
}

.Profile-Container-personalDetails .intl-tel-input input,
.Profile-Container-personalDetails .intl-tel-input input[type='text'],
.Profile-Container-personalDetails .intl-tel-input input[type='tel'] {
    width: 300px;
}

@media (max-width: 991px) {
    .Dashboard .Full-Container {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .Dashboard .Full-Container .Profile-Container-personalDetails .MuiFormControl-root,
    .Dashboard .Full-Container .Profile-Container-LocationDetails .MuiFormControl-root {
        width: 95%;
    }
    .Dashboard .Full-Container h5 {
        padding-left: 0;
    }
}
@media (max-width: 425px) {
    .Dashboard .Full-Container .Profile-Container-personalDetails > div,
    .Dashboard .Full-Container .Profile-Container-LocationDetails > div,
    .Dashboard .Full-Container .ProfilePasswordInput > div {
        flex: 0 1 100%;
    }
    .Dashboard .Full-Container .ProfilePasswordInput .MuiFormControl-root {
        width: 100%;
    }
    .Dashboard .Full-Container .profileButtons .MuiButtonBase-root {
        width: auto;
        max-width: 150px;
        min-width: 100px;
        margin-right: 5px;
        margin-left: 6px;
    }
}

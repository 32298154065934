.staticContainer {
    text-align: left;
    color: #333;
}

.staticTitle {
    font-size: 20px;
    font-weight: 600;
}

.staticContainer h4 {
    font-size: 15px;
    margin-bottom: 15px;
}

.staticSubtitle {
    font-size: 18px;
    font-weight: 600;
}

.staticContainer p {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 26px;
}

.staticContainer .important {
    font-weight: 500;
    font-size: 15px;
}

.staticContainer li {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 26px;
}

.mnhtVw {
    min-height: 62vh;
}
